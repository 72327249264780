authors-element {
  --leftMargin: 0pt;
  --rightMargin: 0pt;
  --marginSize: calc(var(--leftMargin) + var(--rightMargin));
  margin-left: var(--leftMargin);
  margin-right: var(--rightMargin);
  width: auto;
  user-select: none;
  background-color: var(--suggestionBackgroundColor);

  align-items: center;

  margin-bottom: 5px;

  @supports not (-ms-high-contrast: none) {
    /* Non-IE styles here */
    display: flex;
    flex-direction: row;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE styles here */
    display: block;
  }

  &[lock] {
    box-shadow: inset var(--colors-neutral-5) var(--contentWidth) 0,
      0 0 0 1px var(--colors-neutral-20);
  }
}

.container {
  user-select: none;
  height: 6rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0;
  position: relative;

  .tooltip {
    user-select: none;
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}

.contentWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: var(--colors-neutral-50);
  outline: 0;
  /* Neutral/2 */
  background-color: var(--colors-neutral-2);
  /* Neutral/10 */

  border: 1px solid var(--colors-neutral-10);
  box-sizing: border-box;
  border-radius: 4px;
  padding-left: 44px;
  padding-right: 44px;

  .iconWrapper {
    margin-right: 16px;
  }

  .info {
    font-family: Inter !important;
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: center;
  }
}
