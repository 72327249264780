.annotationsList {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  overflow: auto;
  padding: 1rem 1rem 2rem;
}

.emptyState {
  margin-top: 2rem;
}

.emptyStateFiltersApplied {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.emptyStateSearchBy {
  font-size: 1.75rem;
  font-weight: 600;
  color: var(--colors-neutral-100);
  line-height: 3rem;
  text-align: center;
  margin-top: 1rem;
}
