[ispagenode='true'] {
  td {
    break-inside: avoid;
    padding-top: 0;
    padding-bottom: 0;
    width: var(--width);
    min-width: 0.35cm;
    max-width: 55.87cm;

    --contentWidth: var(--width);

    & > * {
      overflow-wrap: break-word;
      word-wrap: break-word;
      word-break: break-word;
      hyphens: auto;
    }

    &:focus {
      outline: none;
    }

    paragraph-element,
    track-ins-element,
    track-del-element,
    figure-element {
      width: auto;
    }

    &[data-selected='true'] {
      background-image: linear-gradient(
        to right,
        rgba(var(--colors-blue-100), 0.25),
        rgba(var(--colors-blue-100), 0.25)
      );
    }

    &[data-row-position='start'] {
      box-shadow: inset 0px 2px 0px 0px var(--colors-blue-105);
    }

    &[data-row-position='end'] {
      box-shadow: inset 0px -2px 0px 0px var(--colors-blue-105);
    }

    &[data-col-position='start'] {
      box-shadow: inset 2px 0px 0px 0px var(--colors-blue-105);
    }

    &[data-col-position='end'] {
      box-shadow: inset -2px 0px 0px 0px var(--colors-blue-105);
    }

    &[data-row-position='start'][data-col-position='start'] {
      box-shadow: inset 0px 2px 0px 0px var(--colors-blue-105),
        inset 2px 0px 0px 0px var(--colors-blue-105);
    }

    &[data-row-position='start'][data-col-position='end'] {
      box-shadow: inset 0px 2px 0px 0px var(--colors-blue-105),
        inset -2px 0px 0px 0px var(--colors-blue-105);
    }

    &[data-row-position='end'][data-col-position='start'] {
      box-shadow: inset 0px -2px 0px 0px var(--colors-blue-105),
        inset 2px 0px 0px 0px var(--colors-blue-105);
    }

    &[data-row-position='end'][data-col-position='end'] {
      box-shadow: inset 0px -2px 0px 0px var(--colors-blue-105),
        inset -2px 0px 0px 0px var(--colors-blue-105);
    }

    &[data-row-position='startEnd'] {
      box-shadow: inset 0px 2px 0px 0px var(--colors-blue-105),
        inset 0px -2px 0px 0px var(--colors-blue-105);
    }

    &[data-col-position='startEnd'] {
      box-shadow: inset 2px 0px 0px 0px var(--colors-blue-105),
        inset -2px 0px 0px 0px var(--colors-blue-105);
    }

    &[data-row-position='startEnd'][data-col-position='start'] {
      box-shadow: inset 0px 2px 0px 0px var(--colors-blue-105),
        inset 0px -2px 0px 0px var(--colors-blue-105), inset 2px 0px 0px 0px var(--colors-blue-105);
    }

    &[data-row-position='startEnd'][data-col-position='end'] {
      box-shadow: inset 0px 2px 0px 0px var(--colors-blue-105),
        inset 0px -2px 0px 0px var(--colors-blue-105), inset -2px 0px 0px 0px var(--colors-blue-105);
    }

    &[data-col-position='startEnd'][data-row-position='start'] {
      box-shadow: inset 2px 0px 0px 0px var(--colors-blue-105),
        inset -2px 0px 0px 0px var(--colors-blue-105), inset 0px 2px 0px 0px var(--colors-blue-105);
    }

    &[data-col-position='startEnd'][data-row-position='end'] {
      box-shadow: inset 2px 0px 0px 0px var(--colors-blue-105),
        inset -2px 0px 0px 0px var(--colors-blue-105), inset 0px -2px 0px 0px var(--colors-blue-105);
    }

    &[data-row-position='startEnd'][data-col-position='startEnd'] {
      box-shadow: inset 0px 2px 0px 0px var(--colors-blue-105),
        inset 0px -2px 0px 0px var(--colors-blue-105), inset 2px 0px 0px 0px var(--colors-blue-105),
        inset -2px 0px 0px 0px var(--colors-blue-105);
    }

    &[lock] {
      border: solid 1px var(--colors-neutral-50);
      background-color: var(--colors-neutral-5);
    }

    &[data-alignment='l'],
    &[data-alignment='left']{
      paragraph-element,
      figure-element {
        text-align: left !important;
      }

      table {
        margin-left: auto !important;
      }
    }

    &[data-alignment='c'],
    &[data-alignment='center']{
      paragraph-element,
      figure-element {
        text-align: center !important;
      }

      table {
        margin: 0 auto !important;
      }
    }

    &[data-alignment='r'],
    &[data-alignment='right']{

      paragraph-element,
      figure-element {
        text-align: right !important;
      }
      
      table {
        margin-left: auto !important;
      }
    }

    &[data-alignment='j'],
    &[data-alignment='justify']{
      paragraph-element,
      figure-element {
        text-align: justify !important;
      }
      table {
        margin-left: auto !important;
      }
    }

    &[data-vertical-alignment='t'],
    &[data-vertical-alignment='top'] {
      vertical-align: top !important; 
    }
    &[data-vertical-alignment='c'],
    &[data-vertical-alignment='center'] {
      vertical-align: middle !important;
    }

    &[data-vertical-alignment='b'],
    &[data-vertical-alignment='bottom'] {
      vertical-align: bottom !important;
    }

    > table[data-alignment='l'],
    > table[data-alignment='left'] {
      margin-right: auto;
    }

    > table[data-alignment='c'],
    > table[data-alignment='center'] {
      margin-left: auto;
      margin-right: auto;
    }

    > table[data-alignment='r'],
    > table[data-alignment='right'] {
      margin-left: auto;
    }
  }
}
